import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { storyCard, storyImage } from "../styles/stories.module.scss"
import lynne from '../images/lynne-steblecki.png'

const Stories = () => (
    <Layout>
        <Seo title="Stories" />
        <section>
            <div style={{ backgroundImage: 'radial-gradient(circle at 100% 100%, #872424 0%, #9A2020 38%, #EF0E0E 82%)' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8}>

                            <div style={{ padding: '10rem 0 7rem', color: 'white' }} className="text-center">
                                <h1>
                                    Patient Stories
            </h1>
                                <p style={{ fontSize: '1.4rem' }}>
                                    Improving the lives of our patients is at the heart of all we do
            </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
                <svg width="100%" height="100" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ marginTop: '-4rem' }}>
                    <path d="M0,120 L0,0 Q250,80 500,0 L500,120 Z" fill="#FAFAFA" />
                </svg>
            </div>
        </section>
        <section>
            <Container>
                <Row>
                    <Col md={{ span: 9, offset: 3 }}>
                        <div className={`${storyCard} card`}>
                            <h2>Lyyne Steblecki</h2>
                            <p>
                                Thirteen years ago, Lynn Steblecki was diagnosed with systemic scleroderma, a rare auto-immune disease which sees the body attacks its own connective tissues. The condition causes scarring and hard, thickened areas of tissue. There is currently no cure.
                                <br /><br />For patients with systemic scleroderma, the disease often starts in the skin, resulting in loss of flexibility, spreading throughout the body and hardening key organs such as the lungs, heart, gut and kidneys.
                                <br /><br />For Lynn, formerly a business woman, this has brought about mobility difficulties and many other health problems.
                                <br /><br />“My original diagnosis was a shock at a time when information was scarce”, explains Lynn, who was born in Yorkshire and spent many years in Zimbabwe before returning to the UK in 1996.
                                <br /><br />“It was very scary. I was working in London at the time and had gone to my GP with painful swollen hands which were tender to touch and I was very tired all the time. Originally I was sent away but I knew something wasn’t right and after six months I finally ended up at the Royal Free – which is the national centre for scleroderma – where I was diagnosed and treated.
                                <br /><br />“I’m in and out of the hospital. Every three months I’m picked up from my home in the New Forest and spend a week on 11 west ward for in-patient treatment. I’m given a powerful intravenous infusion of drugs to help manage the disease. In my case, the drug has to be given continuously for five days but many patients I know have this treatment in the out-patient clinic."
                                <br /><br />“The Royal Free has been part of my life for a long time and my experience has been good. My condition is very complicated and has brought me into contact with many departments and areas across the hospital. I have regular echocardiograms, my lung function also needs to be monitored. My hands are rigid and I’ve had operations to help with that and stem cells have been inserted into my face to help with facial movement."
                                <br /><br />“As a woman, I have found the effects on my facial features devastating. Last year, along with a group of specialists, I was invited to give a talk at Downing Street about the condition and its treatment. I’m also involved with the Royal Free Charity to raise vital funds for research into reconstructive surgery and scarring.”
                            </p>


                            <div className={`${storyImage} card`} style={{ backgroundImage: `url(${lynne})` }}>
                                
                            </div>



                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
)

export default Stories
